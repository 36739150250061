import "./preloader.css"

function Preloader() {
	return (
		<div className='preloader'>
			<p>Ładowanie</p>
		</div>
	)
}

export default Preloader
